import './App.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './AppRoutes';
import './sass/main.scss'
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate()

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  const isTokenExpired = () => {
    const expiryTime = localStorage.getItem("tokenExpiry");
    if (!expiryTime) return true;
    const currentTime = Date.now();
    return currentTime > parseInt(expiryTime, 10);
  };

  useEffect(() => {
    const cashierToken = localStorage.getItem("tokenExpiry");

    if (!cashierToken || isTokenExpired()) {
      navigate('/');
    }
  }, [navigate]);


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppRoutes />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          theme="colored"
          pauseOnHover={false}
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
