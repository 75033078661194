import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Toolbar, Typography } from '@mui/material'
import PrimaryButton from '../components/PrimaryButton'
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const UserLogin = () => {

    const [userDetails, setUserDetails] = useState({
        name: "",
        phone: "",
        email: ""
    });
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const validateForm = () => {
        let formErrors = {};

        if (!userDetails?.name?.trim()) {
            formErrors.name = "Username is required."
        }

        if (!userDetails?.phone?.trim()) {
            formErrors.phone = "Phone is required."
        }
        if (userDetails?.phone?.length !== 10) {
            formErrors.phone = "Phone number must be exactly 10 digits."
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    const handleLogin = async () => {
        if (!validateForm()) {
            return;
        }
        setIsLoading(true)
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return;
            }

            const userDetailsCopy = { ...userDetails };
            if (userDetailsCopy.email === "") {
                userDetailsCopy.email = null;
            }

            const response = await axios.post('kiosk-user', userDetailsCopy, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.status === true) {
                const kioskUser = response?.data?.data?.dbResponse;
                localStorage.setItem("kioskUser", JSON.stringify(kioskUser));
                const message = response?.data?.data?.message;
                toast.success(message, {
                    position: "bottom-center",
                    autoClose: 1000,
                    theme: "colored",
                });
                navigate('/products');
            }

        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412
                ) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
        setIsLoading(false)
    };

   
    return (
        <Box sx={{
            textAlign: "center", background: "#71bae5", minHeight: '100vh', display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', position: 'relative', overflow: 'hidden'
        }}>
            <Box sx={{ position: 'absolute', top: 100 }}>
                <img src="/assets/Vector-one.png" alt="cloud-one" />
            </Box>
            <Box sx={{ position: 'absolute', right: 0, top: 200 }}>
                <img src="/assets/Vector-two.png" alt="cloud-one" />
            </Box>
            <Grid container justifyContent='center' alignItems={'center'} sx={{ flex: 1 }}>
                <Grid item md={4} sm={6} xs={10}>
                    <img src='/assets/auth-brand-logo.png' alt="brand-logo" style={{ width: '100px' }} />
                    <Typography sx={{ fontSize: "25px", color: 'white', fontWeight: 'bold' }}>
                        Hey!
                        <br />
                        Lets Get You a Cup!
                    </Typography>
                    <Box sx={{ textAlign: 'left' }}>
                        <label style={{ color: 'white' }}>What's your name</label>
                        <TextField
                            autoComplete="false"
                            fullWidth
                            name='name'
                            type='text'
                            value={userDetails?.name}
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Tap here to type"
                            variant="outlined"
                            onChange={handleChange}
                            error={!!errors.name}
                        />
                        <p style={{ fontSize: '12px', marginTop: '-1px', color: 'red' }}>{errors.name}</p>
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{ color: 'white' }}>What's your Number <img width={'16'} style={{ marginBottom: '-3px' }} src='/assets/whatsapp.png' /></label>
                        <TextField
                            autoComplete="false"
                            type='text'
                            fullWidth
                            name='phone'
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Tap here to type"
                            variant="outlined"
                            value={userDetails?.phone}
                            onChange={handleChange}
                            error={!!errors.phone}
                        />
                        <p style={{ fontSize: '12px', marginTop: '-1px', color: 'red' }}>{errors.phone}</p>
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{ color: 'white' }}>What's your Email (optional)</label>
                        <TextField
                            autoComplete="false"
                            type='email'
                            fullWidth
                            name='email'
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                            placeholder="Tap here to type"
                            variant="outlined"
                            value={userDetails?.email}
                            onChange={handleChange}
                        />
                    </Box>
                    <Grid container justifyContent={'center'} style={{ marginTop: '8px' }}>
                        <Grid item sm={12} md={12}>
                            <PrimaryButton title={'Proceed'}
                                onClick={handleLogin}
                                disabled={isLoading}
                                spinner={isLoading}
                                disabledTitle={'Logging In'}
                                style={{
                                    borderRadius: "30px", fontSize: "18px",
                                    width: '100%',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', top: 600 }}>
                <img src="/assets/Vector-one.png" alt="cloud-three" />
            </Box>

            <Box sx={{ position: 'absolute', top: 650, right: 0 }}>
                <img src="/assets/Vector-two.png" alt="cloud-three" />
            </Box>
        </Box>
    )
}

export default UserLogin
