import React from 'react';

const PrimaryButton = ({ title, onClick, disabled, spinner = true, disabledTitle, style, disabledColor = '#00b0ff' }) => {
    return (
        <div style={{ width: style?.width || '100%', display: 'flex', justifyContent: 'center' }}>
            <button
                onClick={disabled ? null : onClick}
                disabled={disabled}
                className={`primary-button-container ${disabled ? 'disabled' : ''}`}
                style={{
                    ...style,
                    backgroundColor: disabled ? disabledColor : "#00b0ff",
                    padding: '15px 0',
                    width: style?.width || '100%',
                }}
            >
                {disabled && spinner && (
                    <div className="spinner">
                        <div className="circle"></div>
                    </div>
                )}

                <span className='title'>{disabled ? disabledTitle : title}</span>
            </button>
        </div>
    );
};

export default PrimaryButton;
