import { Box } from '@mui/material'
import React from 'react'
import NavBar from './components/NavBar'
import { Outlet, useLocation } from 'react-router-dom'

const AppLayout = () => {
    const location = useLocation();
    return (
        <>
            <Box sx={{ background: "#f1f3f4" }}>
                {<Outlet />}
            </Box>
        </>
    )
}

export default AppLayout
